import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css";
import MastercardIcon from "../../public/img/icons/Mastercard.vue";
import VisaIcon from "../../public/img/icons/Visa.vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      visa: {
        component: VisaIcon,
      },
      mastercard: {
        component: MastercardIcon,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: process.env.VUE_APP_PRIMARY_COLOR,
        secondary: process.env.VUE_APP_SECONDARY_COLOR,
        accent: process.env.VUE_APP_ACCENT_COLOR,
        error: process.env.VUE_APP_ERROR_COLOR,
        info: process.env.VUE_APP_INFO_COLOR,
        success: process.env.VUE_APP_SUCCESS_COLOR,
        warning: process.env.VUE_APP_WARNING_COLOR,
      },
    },
  },
});
