import {
  ICreditCard,
  IUserProfile,
  IWallet,
  IKeycloak,
  IAccessRight,
  IContent,
  IPublisher,
  IPayin,
  IContentCredit,
  IPurchase,
  IInvoice,
} from "@/interfaces";
import { State, AppNotification } from "./state";
import { getStoreAccessors } from "typesafe-vuex";

export const mutations = {
  setEnvironment(state: State, payload: string) {
    state.environment = payload;
  },
  setKeycloak(state: State, payload: IKeycloak) {
    state.keycloak = payload;
  },
  setLogInError(state: State, payload: boolean) {
    state.logInError = payload;
  },
  setSignUpError(state: State, payload: boolean) {
    state.signUpError = payload;
  },
  setUsernameExists(state: State, payload: boolean) {
    state.usernameExists = payload;
  },
  setUserProfile(state: State, payload: IUserProfile) {
    state.userProfile = payload;
    state.userProfile.initials =
      (payload.first_name?.charAt(0) || "") +
      (payload.last_name?.charAt(0) || "");
    if (!state.userProfile.initials) {
      state.userProfile.initials = payload.email.charAt(0);
    }
  },
  addNotification(state: State, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: State, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload
    );
  },
  setCreditCards(state: State, payload: ICreditCard[]) {
    state.creditCards = payload;
  },
  setWallet(state: State, payload: IWallet) {
    state.wallet = payload;
  },
  setAccessRights(state: State, payload: IAccessRight[]) {
    state.accessRights = payload;
  },
  setContents(state: State, payload: IContent[]) {
    state.contents = payload;
  },
  setPublishers(state: State, payload: IPublisher[]) {
    state.publishers = payload;
  },
  setPayins(state: State, payload: IPayin[]) {
    state.payins = payload;
  },
  setPurchases(state: State, payload: IPurchase[]) {
    state.purchases = payload;
  },
  appendTransactions(state: State, payload: (IContentCredit | IInvoice | IPurchase)[]) {
    state.transactions = state.transactions.concat(payload);
  },
};

const { commit } = getStoreAccessors<State | any, State>("");

export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetUsernameExists = commit(mutations.setUsernameExists);
export const commitSetSignUpError = commit(mutations.setSignUpError);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetCreditCards = commit(mutations.setCreditCards);
export const commitSetWallet = commit(mutations.setWallet);
export const commitSetAccessRights = commit(mutations.setAccessRights);
export const commitSetContents = commit(mutations.setContents);
export const commitSetPublishers = commit(mutations.setPublishers);
export const commitSetPayins = commit(mutations.setPayins);
export const commitSetPurchases = commit(mutations.setPurchases);
export const commitAppendTransactions = commit(mutations.appendTransactions);
