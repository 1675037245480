
import { Component, Vue } from "vue-property-decorator";
import NotificationsManager from "@/components/NotificationsManager.vue";
import updateToken from "@/middlewares/update-token";

@Component({
  components: {
    NotificationsManager,
  },
  watch: {
    $route() {
      updateToken();
    },
  },
})
export default class App extends Vue {}
