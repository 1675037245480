import axios from "axios";
import qs from "qs";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  ICreditCardCreate,
  ICreditCard,
  IRechargeWalletCreate,
  IRechargeWallet,
  IWallet,
  ITab,
  IEmailVerificationToken,
  IAccessRight,
  IInvoice,
  IContent,
  IPublisher,
  IPayin,
  IPurchase,
  IContentCredit,
  IMangopayCreditCardRegistration,
  IMangopayCardData,
  IMangopayUpdateCreditCardRegistration,
  ISlimpayOrder,
  ISlimpayOrderCreate,
  IGocardlessBillingRequestCreate,
  IGocardlessBillingRequest,
  ITinkReport,
} from "./interfaces";

// Out-of-the-box, FastApi only supports the format
// "param=value1&param=value2" for list parameters
// This config allows to switch from the default param[]=value1&param[]=value2
// to the expected FastAPI format
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { indices: false });
};


function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async getMe(token: string) {
    return axios.get<IUserProfile>(
      `${apiUrl}/v1/users/me`,
      authHeaders(token)
    );
  },
  async getMeOrCreate(token: string) {
    return axios.post(
      `${apiUrl}/v1/users/get-or-create`,
      null,
      authHeaders(token)
    );
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/v1/users/me`,
      data,
      authHeaders(token)
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(
      `${apiUrl}/v1/users`,
      authHeaders(token)
    );
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/v1/users`, data, authHeaders(token));
  },
  async resetPassword(token: string, password: string) {
    return axios.post(
      `${apiUrl}/v1/users/reset-password/me`,
      password,
      authHeaders(token)
    );
  },
  async createCreditCard(token: string, data: ICreditCardCreate) {
    return axios.post(
      `${apiUrl}/v1/payments/credit-cards`,
      data,
      authHeaders(token)
    );
  },
  async getCreditCards(token: string) {
    return axios.get<ICreditCard[]>(
      `${apiUrl}/v1/payments/credit-cards`,
      authHeaders(token)
    );
  },
  async createRechargeWallet(token: string, data: IRechargeWalletCreate) {
    return axios.post<IRechargeWallet>(
      `${apiUrl}/v1/payments/recharge-wallet`,
      data,
      authHeaders(token)
    );
  },
  async getWallet(token: string) {
    return axios.get<IWallet>(
      `${apiUrl}/v1/payments/wallet`,
      authHeaders(token)
    );
  },
  async getTab(token: string) {
    return axios.get<ITab>(
      `${apiUrl}/v1/tab`,
      authHeaders(token)
    );
  },
  async sendVerificationEmail(token: string) {
    return axios.post(
      `${apiUrl}/v1/send-verification-email`,
      null,
      authHeaders(token)
    );
  },
  async verifyEmail(token: string, data: IEmailVerificationToken) {
    return axios.post(
      `${apiUrl}/v1/verify-email`,
      data,
      authHeaders(token)
    );
  },
  async getAccessRights(token: string, accessRightIds?: number[]) {
    return axios.get<IAccessRight[]>(`${apiUrl}/v1/access-rights`, {
      params: {
        access_right_ids: accessRightIds,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getContents(token: string, contentIds?: number[]) {
    return axios.get<IContent[]>(`${apiUrl}/v1/contents`, {
      params: {
        content_ids: contentIds,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getPublishers(token: string, publisherIds?: number[]) {
    return axios.get<IPublisher[]>(`${apiUrl}/v1/publishers`, {
      params: {
        publisher_ids: publisherIds,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getPayins(
    token: string,
    createdBefore: string = undefined,
    createdAfter: string = undefined,
    statuses: string = undefined,
    limit: number = undefined
  ) {
    return axios.get<IPayin[]>(`${apiUrl}/v1/payins/me`, {
      params: {
        created_before: createdBefore,
        created_after: createdAfter,
        statuses: statuses,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getContentCredits(
    token: string,
    createdBefore: string = undefined,
    createdAfter: string = undefined,
    limit: number = undefined
  ) {
    return axios.get<IContentCredit[]>(`${apiUrl}/v1/content-credits/me`, {
      params: {
        created_before: createdBefore,
        created_after: createdAfter,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getPurchases(
    token: string,
    createdBefore: string = undefined,
    createdAfter: string = undefined,
    limit: number = undefined
  ) {
    return axios.get<IPurchase[]>(`${apiUrl}/v1/purchases/me`, {
      params: {
        created_before: createdBefore,
        created_after: createdAfter,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getTransactions(
    token: string,
    createdBefore: string = undefined,
    // createdAfter: string = undefined,
    limit: number = undefined
  ) {
    return axios.get<(IPurchase | IInvoice | IContentCredit)[]>(`${apiUrl}/v1/transactions`, {
      params: {
        created_before: createdBefore,
        // created_after: createdAfter,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async createCardRegistration(
    token: string,
    data: IMangopayCreditCardRegistration
  ) {
    return axios.post(
      `${apiUrl}/v1/payments/card-registration`,
      data,
      authHeaders(token)
    );
  },

  async updateCardRegistration(
    token: string,
    registrationId: number,
    data: IMangopayUpdateCreditCardRegistration
  ) {
    return axios.put(
      `${apiUrl}/v1/payments/card-registration/${registrationId}`,
      data,
      authHeaders(token)
    );
  },

  async deactivateCard(token: string, cardId: number) {
    return axios.put(
      `${apiUrl}/v1/payments/deactivate-card/${cardId}`,
      {},
      authHeaders(token)
    );
  },

  async getPayin(token: string, payinId: number) {
    return axios.get(
      `${apiUrl}/v1/payments/mangopay-payin/${payinId}`,
      authHeaders(token)
    );
  },

  async getPassePartoutPayinByMangopayPayinId(token: string, payinId: number) {
    return axios.get(
      `${apiUrl}/v1/payins/mangopay-payin/${payinId}`,
      authHeaders(token)
    );
  },
  
  async refreshWallet(token: string) {
    return axios.put(
      `${apiUrl}/v1/payments/refresh-wallet`,
      {},
      authHeaders(token)
    )
  },
  async createSlimpayOrder(token: string, data: ISlimpayOrderCreate) {
    return axios.post<ISlimpayOrder>(
      `${apiUrl}/v1/slimpay/orders`,
      data,
      authHeaders(token),
    );
  },
  async revokeMandate(token: string) {
    axios.post(`${apiUrl}/v1/slimpay/revoke-mandate`, {}, authHeaders(token))
  },
  async createRandomMandate(token: string) {
    axios.post(`${apiUrl}/v1/tab/random-mandate`, {}, authHeaders(token))
  },
  async checkUserHasValidMandate(token: string) {
    axios.post(`${apiUrl}/v1/slimpay/check-user-has-valid-mandate`, {}, authHeaders(token))
  },
  async createGocardlessBillingRequest(token: string, data: IGocardlessBillingRequestCreate) {
    return axios.post<IGocardlessBillingRequest>(
      `${apiUrl}/v1/gocardless/tink-prefilled-billing-request`,
      data,
      authHeaders(token),
    );
  },
  async getTinkReport(token: string, accountVerificationReportId: string) {
    return axios.get<ITinkReport>(
      `${apiUrl}/v1/tink/account_verification_reports/${accountVerificationReportId}`,
      authHeaders(token),
    );
  },

  // MANGOPAY CALLS
  async postCardData(url: string, data: IMangopayCardData) {
    return axios.post(url, qs.stringify(data), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },

};
