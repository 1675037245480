import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () =>
        import(/* webpackChunkName: "start" */ "./views/Start.vue"),
      children: [
        {
          path: "/",
          redirect: "/home",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/Main.vue"),
          children: [
            {
              path: "/home",
              component: () =>
                import(/* webpackChunkName: "home" */ "./views/Home.vue"),
            },
            {
              path: "/profile",
              component: () =>
                import(
                  /* webpackChunkName: "profile" */ "./views/profile/UserProfile.vue"
                ),
            },
            {
              path: "/reset-password",
              component: () =>
                import(
                  /* webpackChunkName: "reset-password" */ "./views/profile/ResetPassword.vue"
                ),
            },
            {
              path: "/recharge-wallet",
              component: () =>
                import(
                  /* webpackChunkName: "recharge-wallet" */ "./views/payment/RechargeWallet.vue"
                ),
            },
            {
              path: "recharge-wallet/payment-info",
              component: () =>
                import(
                  /* webpackChunkName: "payment-info" */ "./views/payment/PaymentInfo.vue"
                ),
            },
            {
              path: "/recharge-wallet/confirmation",
              component: () =>
                import(
                  /* webpackChunkName: "payment-confirmation" */ "./views/payment/PaymentConfirmation.vue"
                ),
            },
            {
              path: "/setup-tab",
              component: () =>
                import(
                  /* webpackChunkName: "setup-tab" */ "./views/payment/SetupTab.vue"
                ),
            },
            {
              path: "/setup-tab/sign-mandate",
              component: () =>
                import(
                  /* webpackChunkName: "setup-tab-bank-redirection" */ "./views/payment/SetupTabSignMandate.vue"
                ),
            },
            {
              path: "/setup-tab/success",
              component: () =>
                import(
                  /* webpackChunkName: "setup-tab-success" */ "./views/payment/SetupTabSuccess.vue"
                ),
            },
            {
              path: "/setup-tab/failure",
              component: () =>
                import(
                  /* webpackChunkName: "setup-tab-failure" */ "./views/payment/SetupTabFailure.vue"
                ),
            },
            {
              path: "/setup-tab/cancel",
              component: () =>
                import(
                  /* webpackChunkName: "setup-tab-cancel" */ "./views/payment/SetupTabCancel.vue"
                ),
            },
          ],
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/public",
      component: () =>
        import(/* webpackChunkName: "public" */ "./views/public/Public.vue"),
      children: [
        {
          path: "terms-of-service",
          component: () =>
            import(
              /* webpackChunkName: "terms of services" */ "./views/public/TermsOfServices.vue"
            ),
        },
        {
          path: "confidentiality-rules",
          component: () =>
            import(
              /* webpackChunkName: "condidentiality rules" */ "./views/public/ConfidentialityRules.vue"
            ),
        },
        {
          path: "legal-notices",
          component: () =>
            import(
              /* webpackChunkName: "legal notices" */ "./views/public/LegalNotices.vue"
            ),
        },
      ],
    },
    {
      path: "/email-verification",
      component: () =>
        import(
          /* webpackChunkName: "email-verification" */ "./views/EmailVerification.vue"
        ),
    },
    {
      path: "/profile-not-found",
      component: () =>
        import(
          /* webpackChunkName: "profile-not-found" */ "./views/ProfileNotFound.vue"
        ),
    },
    {
      path: "/*",
      component: () => import(/* webpackChunkName: "404" */ "./views/404.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // We wait for Keycloak init, then we can call all methods safely
    while (Vue.prototype.$keycloak.createLoginUrl === null) {
      // sleep(100)
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    if (Vue.prototype.$keycloak.authenticated) {
      next();
    } else {
      const loginUrl = Vue.prototype.$keycloak.createLoginUrl();
      window.location.replace(loginUrl);
    }
  } else {
    next();
  }
});

export default router;
