import { State } from "./state";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
  hasAdminAccess: (state: State) => {
    return (
      state.userProfile &&
      state.userProfile.is_superuser &&
      state.userProfile.is_active
    );
  },
  loginError: (state: State) => state.logInError,
  signUpError: (state: State) => state.signUpError,
  usernameExists: (state: State) => state.usernameExists,
  userProfile: (state: State) => state.userProfile,
  firstNotification: (state: State) =>
    state.notifications.length > 0 && state.notifications[0],
  creditCards: (state: State) => state.creditCards,
  wallet: (state: State) => state.wallet,
  tab: (state: State) => state.tab,
  accessRights: (state: State) => state.accessRights,
  transactions: (state: State) => state.transactions,
  contents: (state: State) => state.contents,
  publishers: (state: State) => state.publishers,
};

const { read } = getStoreAccessors<State, State>("");

export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readLoginError = read(getters.loginError);
export const readSignUpError = read(getters.signUpError);
export const readUsernameExists = read(getters.usernameExists);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readCreditCards = read(getters.creditCards);
export const readWallet = read(getters.wallet);
export const readTab = read(getters.tab);
export const readAccessRights = read(getters.accessRights);
export const readTransactions = read(getters.transactions);
export const readContents = read(getters.contents);
export const readPublishers = read(getters.publishers);
